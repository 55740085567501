import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")

  return (_openBlock(), _createBlock(_component_Dropdown, {
    options: _ctx.lots,
    modelValue: _ctx.inputVal,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.inputVal = $event)),
    optionLabel: "name",
    optionValue: "id",
    class: [{ 'p-invalid': _ctx.invalid }],
    placeholder: "Выберите поставку",
    onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.filter()))
  }, null, 8, ["options", "modelValue", "class"]))
}