
import { ref, onMounted, defineComponent, computed } from 'vue';

import { useToast } from 'primevue/usetoast';
import Dropdown from 'primevue/dropdown';
// import Button from 'primevue/button';

import { Lot } from '@/classes/models/Lot';
import { LotApi } from '@/classes/api/LotApi';

// import LotForm from '../../Lots/LotForm.vue';
import CustomError from '@/classes/models/CustomError';

export default defineComponent({
  emits: ['update:modelValue', 'filter'],
  components: {
    // 'lot-form': LotForm,
    Dropdown,
    // Button
  },
  props: {
    modelValue: Number,
    invalid: Boolean,
    id: String,
  },

  setup(props, ctx) {
    const lotFormDialog = ref(false);
    const lots = ref<Lot[]>([]);
    const lotApi = new LotApi();
    const toast = useToast();

    const inputVal = computed({
      get: () => props.modelValue,
      set: val => {
        ctx.emit('update:modelValue', val);
      },
    });

    const filter = () => {
      ctx.emit('filter');
    };

    const update = async () => {
      try {
        lots.value = await lotApi.fetchAll();
      } catch (error) {
        if (error instanceof CustomError) {
          error.show('error', 5000, toast);
        } else {
          console.log(error);
        }
      }
    };

    onMounted(() => {
      update();
    });

    const addButtonHandler = () => {
      lotFormDialog.value = true;
    };

    return {
      inputVal,
      lots,
      lotFormDialog,
      update,
      addButtonHandler,
      filter,
    };
  },
});
